import { back_arrow, enter_arrow_2, left_arrow_from_left } from 'constant/AllIcons'
import { footer_support_features } from 'data/FooterSupport'
import Link from 'next/link'
import React from 'react'

const FooterSupportCard = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <h2 className="h2 mx-auto  text-center max-w-lg mb-4">
        Support is always here for you. However you need it.
      </h2>
      <div className=" grid  grid-cols-1 md:grid-cols-3    gap-x-8  gap-y-10 md:gap-y-12 bg-gray-100 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl">
        {footer_support_features.map((feature) => {
          return (
            <div className=" flex items-center justify-center text-center   flex-col p-[10px] pl-[14px] ">
              <div className=" mb-6 flex items-center justify-center text-primary h-10 w-10 bg-primary/40  ring-8 ring-primary/10  rounded-full ">
                {feature.icon}
              </div>
              <h4 className=" h4 !text-xl  mb-2">{feature.title} </h4>
              <p className="text-lightGray text-base font-medium  leading-[24px]  md:min-h-[100px]">
                {feature.description}
              </p>

              <Link href={feature.button.url}>
                <a className="mt-4  text-primary  text-lg flex items-center gap-2">
                  {feature.button.text} {enter_arrow_2}
                </a>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FooterSupportCard
